import axios from "axios";
import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import MainButton from "../../components/button/MainButton";
import Input from "../../components/input/Input";
import { toastErrorServer, toastSucces } from "../../components/toast/Toast";
import { useGetUserByIdMutation } from "../../store/service/userService";

const UpdateUser = () => {
  const [user, setUser] = useState([]);
  const [getUserById] = useGetUserByIdMutation();
  const params = useParams();
  useEffect(() => {
    getUserById(params.id).then((res) => {
      setUser(res.data.data);
      setIsLoading(true);
    });
  }, []);
  const setIsUniversityNameValid = () => {
    return true;
  };
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [numero, setNumero] = useState("");
  const [isLoading, setIsLoading] = useState("");
  const role = localStorage.getItem("role");
  const [url, setUrl] = useState(
    `https://esi.alecso.org/api/user/updateUser/${params.id}`
  );
  useEffect(() => {
    setName(isLoading || user?.username ? user?.username : "");
    setNumero(isLoading || user?.numero ? user?.numero : "");
    setEmail(isLoading || user?.email ? user?.email : "");
    if (role === "ROLE_RESP_UNI") {
      setUrl(`https://esi.alecso.org/api/user/updateAgent/${params.id}`);
    }
  }, [isLoading]);
  const token = localStorage.getItem("token");
  const navigate = useNavigate();
  const back = () => {
    navigate(-1);
  };
  const updateUser = async () => {
    try {
      const update = await axios({
        method: "PUT",
        url: url,
        data: { username: name, email: email, numero: numero },
        headers: {
          "x-access-token": `${token}`,
        },
      });
      if (update.status == 200) {
        toastSucces("تمت العملية بنجاح !");
      }
    } catch (error) {
      toastErrorServer(`هذا البريد الإلكتروني ليس متاحًا`);
    }
  };

  return (
    <div className="flex flex-col p-10 rounded-md">
      <h1 className="p-4 text-3xl font-bold text-blue-900 text-bold">
        تعديل المستعمل
      </h1>
      <div className="bg-white rounded">
        <div className="flex items-center justify-center w-full pt-8 h-fit">
          <div className="flex items-center justify-center w-1/3 mx-2">
            <div className="flex flex-col items-start justify-center w-full">
              <p className="text-xl text-blue-900"> الاسم </p>
              <Input
                type={"NAME"}
                placeholder={name}
                inputChange={setName}
                value={name}
                setValid={setIsUniversityNameValid}
              />
            </div>
          </div>
          <div className="flex items-center justify-center w-1/3 mx-2">
            <div className="flex flex-col items-start justify-center w-full">
              <p className="text-xl text-blue-900"> البريد الالكتروني </p>
              <Input
                type={"EMAIL"}
                placeholder={email}
                inputChange={setEmail}
                value={email}
                setValid={setIsUniversityNameValid}
              />
            </div>
          </div>
          <div className="flex items-center justify-center w-1/3 mx-2">
            <div className="flex flex-col items-start justify-center w-full">
              <p className="text-xl text-blue-900"> الهاتف </p>
              <Input
                type={"NUMBER"}
                placeholder={numero}
                inputChange={setNumero}
                value={numero}
                setValid={setIsUniversityNameValid}
              />
            </div>
          </div>
          <div className="flex items-center justify-start w-1/3 gap-3 pt-2 pb-3 mx-2">
            <MainButton
              title="تعديل"
              disabled={name === "" || email === "" || numero === ""}
              action={() => {
                updateUser();
              }}
            />
            <MainButton
              title="رجوع"
              action={() => {
                back();
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default UpdateUser;
