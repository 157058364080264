/* eslint-disable react/no-unescaped-entities */
import React from "react";
import PropTypes from "prop-types";
import { Document, Page } from "react-pdf/dist/esm/entry.webpack";
const ContentModal = (diploma) => {
  return (
    <>
      <div className="flex  w-[1440px] justify-between">
        <div className=" w-[500px] ">
          <h5 className="font-bold text-[#607080]">
            البيانات الوصفية "Metadata"
          </h5>
          <div className="flex ">
            <span className="w-40 font-semibold text-[#607080]">* الطالب </span>
            <span className="text-[#607080] ">
              : {diploma?.item?.student?.firstName}
              {diploma?.item?.student?.lastName}
            </span>
          </div>
          <div className="flex ">
            <span className="w-40 font-semibold text-[#607080]">
              * تاريخ الولادة
            </span>
            <span className="text-[#607080]">
              :
              {diploma?.item?.student?.dateOfBirth === "Invalid date"
                ? null
                : diploma?.item?.student?.dateOfBirth}
            </span>
          </div>
          <div className="flex ">
            <span className="w-40 font-semibold text-[#607080]">
              * مكان الولادة
            </span>
            <span className="text-[#607080]">
              : {diploma?.item?.student?.countryOfBirth}
            </span>
          </div>
          <div className="flex ">
            <span className="w-40 font-semibold text-[#607080]">
              * المعرّف الوطني
            </span>
            <span className="text-[#607080]">
              : {diploma?.item?.student?.nin}
            </span>
          </div>
          <div className="flex ">
            <span className="w-40 font-semibold text-[#607080]">
              * نوع الشهادة
            </span>
            <span className="text-[#607080]">: {diploma?.item?.type}</span>
          </div>
          <div className="flex ">
            <span className="w-40 font-semibold text-[#607080]">
              * السنة الجامعية
            </span>
            <span className="text-[#607080] ">: {diploma?.item?.year}</span>
          </div>
          <div className="flex ">
            <span className="w-40 font-semibold text-[#607080]">
              * الاختصاص
            </span>
            <span className="text-[#607080]">
              : {diploma?.item?.speciality}
            </span>
          </div>
          <div className="flex ">
            <span className="w-40 font-semibold text-[#607080]">
              * الملاحظة
            </span>
            <span className="text-[#607080]">: {diploma?.item?.honors}</span>
          </div>
          <div className="flex ">
            <span className="w-40 font-semibold text-[#607080]">* المعدّل</span>
            <span className="text-[#607080]">: {diploma?.item?.average}</span>
          </div>
          <div className="flex ">
            <span className="w-40 font-semibold text-[#607080]">
              * تاريخ المصادقة
            </span>
            <span className="text-[#607080]">
              :
              {diploma?.item?.approvalDate === "Invalid date"
                ? null
                : diploma?.item?.approvalDate}
            </span>
          </div>
          <div className="flex ">
            <span className="w-40 font-semibold text-[#607080]">
              * تاريخ التوقيع
            </span>
            <span className="text-[#607080]">
              :
              {diploma?.item?.signatureDate === "Invalid date"
                ? null
                : diploma?.item?.signatureDate}
            </span>
          </div>
        </div>
        <div className="flex gap-14">
          <div className="w-auto h-auto">
            <div className="border-8 border-black border-solid ">
              <Document
                file={`https://esi.alecso.org/ipfs/${diploma?.item?.hashIpfs}`}
              >
                <Page pageNumber={1} />
              </Document>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
ContentModal.propTypes = {
  diploma: PropTypes.object,
  item: PropTypes.object,
  updateDipl: PropTypes.func,
  setShowUpdate: PropTypes.func,
  showUpdate: PropTypes.bool,
  setShowModal: PropTypes.func,
};
export default ContentModal;
