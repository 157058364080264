import React, { useEffect, useState } from "react";
import { Document, Page } from "react-pdf/dist/esm/entry.webpack";
import PropTypes from "prop-types";
import Spinner from "../../spinner/Spinner";
import ModalLayout from "../../../layouts/ModalLayout";
const DiplomaModalWithCloseButton = ({ item, setOpen }) => {
  const [isShowing, setIsShowing] = useState(false);
  useEffect(() => {
    setIsShowing(true);
  }, [isShowing]);

  return (
    <ModalLayout>
      <div className="relative w-auto max-w-4xl mx-auto my-6">
        {/*content*/}
        <div className="relative flex flex-col w-full bg-white border-0 rounded-lg shadow-sm outline-none focus:outline-none">
          {/*header*/}
          <div className="flex items-start justify-between p-2 bg-blue-900 border-b border-solid rounded-t border-slate-200">
            <h3 className="text-3xl font-semibold text-white">الشهادة</h3>
          </div>
          {/*body*/}
          <div className="relative flex-auto p-4">
            <div className="w-auto h-auto">
              <div className="border-solid ">
                <Document
                  file={"https://esi.alecso.org/ipfs/" + item?.hashIpfs}
                  loading={
                    <div className="flex items-center justify-center w-full">
                      <Spinner />
                    </div>
                  }
                >
                  <Page pageNumber={1} />
                </Document>
              </div>
            </div>
          </div>
          {/*footer*/}
          <div className="flex items-center justify-end p-2 border-t border-solid rounded-b border-slate-200">
            <button
              onClick={() => setOpen(!open)}
              className="bg-[#e6eaee] text-[#1a2026]  leading-normal font-normal uppercase text-base px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
            >
              غلق النافذة
            </button>
          </div>
        </div>
        l
      </div>
    </ModalLayout>
  );
};
DiplomaModalWithCloseButton.propTypes = {
  item: PropTypes.object,
  setOpen: PropTypes.func,
};
export default DiplomaModalWithCloseButton;
