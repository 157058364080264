/* eslint-disable react/no-unescaped-entities */
import React, { useState } from "react";
import ErrorBoundary from "../../utils/ErrorBoundary";
import AddDiplomasUploader from "./AddDiplomasUploader";
import AddDiplomasTable from "./AddDiplomasTable";
import AddDiplomasPDFUploader from "./AddDiplomasPDFUploader";
import MainButton from "../../components/button/MainButton";
import { useDispatch, useSelector } from "react-redux";
import { useAddDiplomasMutation } from "../../store/service/diplomasService";
import {
  ADD_DIPLOMAS_FROM_CSV,
  ADD_FILES,
  ADD_PDFS,
} from "../../store/features/diplomasSlice/diplomasSlice";
import axios from "axios";
import { toast } from "react-toastify";
import jwtDecode from "jwt-decode";
import { useNavigate } from "react-router-dom";
import Spinner from "../../components/spinner/Spinner";
import DeleteDiplomaModal from "../../components/modal/Diploma/DeleteDiplomaModal";
import AddDiplomaErrorModal from "../../components/modal/Student/AddDiplomaErrorModal";
import { clearLocalStorage } from "../../utils/logout";
import { toastErrorServer } from "../../components/toast/Toast";
function AddDiplomasPage() {
  let dispatch = useDispatch();
  let navigate = useNavigate();
  let errorObj = [];
  const [error, setError] = useState([]);
  const [key, setKey] = useState(0);
  const [errorObject, setErrorObject] = useState([]);
  const [nonExistingStudents, setNonExistingStudents] = useState([]);
  const [DiplomaToDelete, setDiplomaToDelete] = useState({});
  const [open, setOpen] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [openError, setOpenError] = useState(false);
  const [selectedMenu, setSelectedMenu] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingData, setIsLoadingData] = useState(false);

  const diploma = useSelector((state) => state.diploma);
  const file = useSelector((state) => state.diploma.files);

  let [addDiplomas] = useAddDiplomasMutation();

  /**
   * @function uploadJSON
   * @description convert table to json
   *
   *
   * @returns data
   */
  const uploadJSON = () => {
    let diplomasData = [...diploma.diplomasToAdd];
    diplomasData.shift();
    let data = diplomasData.map((diploma) => {
      return {
        nin: diploma.nin,
        type: diploma.type,
        speciality: diploma.speciality,
        year: diploma.year,
        signatureDate: diploma.signatureDate,
        honors: diploma.honors,
        approvalDate: diploma.approvalDate,
        average: diploma.average,
      };
    });
    return data;
  };

  /**
   * @function deleteDiploma
   * @description Delete one ligne in the Table
   *
   *
   * @returns
   */
  const deleteDiploma = () => {
    let diplomasTable = [...diploma.diplomasToAdd];
    diplomasTable.splice(key, 1);
    dispatch(ADD_DIPLOMAS_FROM_CSV({ diplomasToAdd: diplomasTable }));
    setOpenModal(!openModal);
  };

  /**
   *
   * @param {*} token
   */
  const addDiplomasApiCall = async (token) => {
    axios({
      method: "POST",
      url: "https://esi.alecso.org/api/diploma/uploadDiplomas",
      data: diploma.pdfs,
      headers: {
        "Content-Type": "multipart/form-data",
        "x-access-token": `${token}`,
      },
    }).then(async () => {
      await addDiplomas({ diplomasToAdd: uploadJSON() }).then(async (res) => {
        dispatch(ADD_DIPLOMAS_FROM_CSV({ diplomasToAdd: [] }));

        if (res.data) {
          toast.success("تمت العملية بنجاح !", {
            position: "top-left",
            theme: "colored",
          });

          dispatch(ADD_FILES({ files: [] }));
          dispatch(ADD_PDFS({ pdfs: [] }));
          setIsLoading(!isLoading);
        } else {
          toast.error(`هنالك خطأ من السرفر  `, {
            position: "top-left",
            theme: "colored",
          });
          setIsLoading(false);
          setOpenError(true);
          setError(res.data.error);
          dispatch(ADD_FILES({ files: [] }));
          dispatch(ADD_PDFS({ pdfs: [] }));
        }
        dispatch(ADD_FILES({ files: [] }));
        dispatch(ADD_PDFS({ pdfs: [] }));
        setIsLoading(false);
      });
    });
  };

  /**
   *
   */
  const relocateToInitialPage = () => {
    clearLocalStorage();
    toastErrorServer("فترة التسجيل الخاصة بك انتهت. أعد الدخول مجدداً ");
    setTimeout(() => {
      navigate("/login");
    }, 1000);
  };

  /**
   *
   */
  const addDiplomasClick = async () => {
    setIsLoading(true);
    const token = localStorage.getItem("token");
    if (jwtDecode(token).exp * 1000 < Date.now()) {
      relocateToInitialPage();
    } else {
      await addDiplomasApiCall(token);
    }
  };

  /**
   * @function checkMenu2
   *
   * @description This function to get access
   *              to menu 2 with check is diplomas exist or not
   *
   * @returns
   */
  const checkMenu2 = () => {
    if (diploma?.diplomasToAdd.length == 0) {
      toastErrorServer(`يرجى إضافة شهائد`);
    } else {
      setSelectedMenu(2);
    }
  };

  /**
   * @function checkMenu3
   *
   * @description This function to get access
   *              to menu 3 with check is lenght diplomas with lenght pdfs
   *
   * @returns
   */
  const checkMenu3 = () => {
    if (file?.length < diploma?.diplomasToAdd.length - 1) {
      toastErrorServer(`يرجى ألتثبت فالبيانات ، يجب رفع ملف شهادة لكل طالب`);
    } else {
      setSelectedMenu(3);
    }
  };
  return (
    <div
      data-testid="adddiplomapage"
      className="flex flex-col justify-end mt-10 px-[3%] "
    >
      <h1 className="mb-2 text-2xl font-bold text-blue-900">إضافة الشهادات</h1>
      <div className="flex flex-col items-center justify-center w-full p-4 bg-white rounded h-fit">
        <ErrorBoundary>
          <div className="flex w-full pt-8 pr-10">
            <div
              onClick={() => {
                setSelectedMenu(1);
              }}
              className={`cursor-pointer w-36 p-2 mb-2 text-blue-800 ${
                selectedMenu === 1 && `border-b-2 border-b-blue-900 bg-blue-50`
              }`}
            >
              1- بيانات الشهادات
            </div>
            <div
              onClick={() => {
                checkMenu2();
              }}
              className={`cursor-pointer w-36 p-2 mb-2 text-blue-800 ${
                selectedMenu === 2 && `border-b-2 border-b-blue-900 bg-blue-50`
              }`}
            >
              2- ملفات الشهادات
            </div>
            <div
              onClick={() => {
                checkMenu3();
              }}
              className={`cursor-pointer w-36 p-2 mb-2 text-blue-800 ${
                selectedMenu === 3 && `border-b-2 border-b-blue-900 bg-blue-50`
              }`}
            >
              3- تأكيد الإضافة
            </div>
          </div>
          {selectedMenu === 1 && (
            <AddDiplomasUploader
              setError={setError}
              setNonExistingStudents={setNonExistingStudents}
              setOpen={setOpen}
              setOpenError={setOpenError}
              openError={openError}
              setErrorObject={setErrorObject}
              isLoadingData={isLoadingData}
              setIsLoadingData={setIsLoadingData}
              errorObj={errorObj}
            />
          )}
          {selectedMenu === 2 && <AddDiplomasPDFUploader />}
          {selectedMenu === 3 && (
            <div className="flex flex-col w-full p-4 rounded bg-blue-50 h-fit">
              <p>
                يُرجى التثبت في الجدول أدناه من بيانات الشهادات وتطابقها مع
                ملفات الPDF. علما أن الضغط على زرّ "تأكيد الإضافة" سيقوم بتحويل
                بيانات الشهادات المرتبطة بملفات PDF إلى المدير من أجل الاعتماد.
                وستبقى باقي البيانات الغير مرتبطة بملفات PDF بطور الانتظار إلى
                أن يتم ربطها أو حذفها يدويا.
              </p>
              <div className="w-40 py-4">
                <MainButton
                  title="   تأكيد الإضافة"
                  action={() => {
                    addDiplomasClick();
                  }}
                  isLoading={isLoading}
                />
              </div>
            </div>
          )}
        </ErrorBoundary>
        <div className="w-full">
          <ErrorBoundary>
            {isLoadingData ? (
              <>
                <div>
                  <span className="mb-4 text-2xl font-bold text-indigo-900">
                    الرجاء الانتظار ، نحن بصدد إجراء العملية
                  </span>
                </div>
                <div className="h-[300px] flex items-center justify-center ">
                  <Spinner />
                </div>
              </>
            ) : (
              <AddDiplomasTable
                isLoadingData={isLoadingData}
                setKey={setKey}
                setOpenModal={setOpenModal}
                setDiplomaToDelete={setDiplomaToDelete}
              />
            )}
          </ErrorBoundary>
        </div>
      </div>
      {open && (
        <AddDiplomaErrorModal
          error={error}
          setOpen={setOpen}
          nonExistingStudents={nonExistingStudents}
          setError={setError}
          errorObject={errorObject}
          setErrorObject={setErrorObject}
          open={open}
          errorObj={errorObj}
          setNonExistingStudents={setNonExistingStudents}
        />
      )}
      {openModal && (
        <DeleteDiplomaModal
          setOpenModal={setOpenModal}
          openModal={openModal}
          deleteDiploma={deleteDiploma}
          DiplomaToDelete={DiplomaToDelete}
        />
      )}
    </div>
  );
}

export default AddDiplomasPage;
