/* eslint-disable react/jsx-no-target-blank */
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Document, Page } from "react-pdf/dist/esm/entry.webpack";
import Spinner from "../../components/spinner/Spinner";
import { useVerifyDiplomaMutation } from "../../store/service/diplomasService";
import certifiedLogo from "../../assets/certified.png";

function VerificationPage() {
  // eslint-disable-next-line no-unused-vars
  let id = useParams();
  let [verify] = useVerifyDiplomaMutation();

  // eslint-disable-next-line no-unused-vars
  const [spinnerActive, setSpinnerActive] = useState(true);
  const [hashIpfs, setHashIpfs] = useState("");
  const [diploma, setDiploma] = useState({});
  useEffect(() => {
    setSpinnerActive(true);

    let data = {
      diplomaId: id.id,
    };
    verify(data).then((res) => {
      setDiploma(res.data.data);

      if (res) {
        setSpinnerActive(false);
        setHashIpfs(res.data.data.hashIpfs);
      }
    });
  }, []);

  return (
    <div className="flex flex-col justify-center h-full p-12 ">
      <h1 className="text-2xl font-bold tex-blue-900">فضاء المؤسسة</h1>
      {!spinnerActive ? (
        <div className="flex flex-col items-start justify-between md:flex md:flex-row">
          {hashIpfs ? (
            <div className="flex flex-col justify-start w-full h-full mt-8">
              <p className="text-sm text-gray-700">التحقق من صحّة شهادة :</p>

              <div className="flex flex-col items-start min-h-[700px] h-fit mt-4 p-4 bg-white rounded-xl w-full">
                <h2 className="mb-8 text-xl font-bold text-blue-900">
                  نتائج البحث
                </h2>
                <div className="flex items-start justify-between w-full h-full">
                  <div className="flex flex-col items-center justify-center w-full">
                    <span className="w-full text-lg text-blue-900 ">
                      البيانات الوصفية
                    </span>
                    {hashIpfs && (
                      <div className="w-[70%] mb-4">
                        <div className="flex w-full py-4 space-x-2 border-b border-b-gray-500">
                          <div className="flex items-start text-sm font-bold ">
                            <span className="mr-4 text-gray-700">
                              السنة الجامعية :
                            </span>
                            <span className="mr-2 "> {diploma?.year}</span>
                          </div>
                        </div>

                        <div className="flex w-full py-4 space-x-2 border-b mt- border-b-gray-500 ">
                          <div className="flex items-start text-sm font-bold ">
                            <span className="mr-4 text-gray-700">
                              الاختصاص:
                            </span>
                            <span className="mr-2 ">{diploma?.speciality}</span>
                          </div>
                        </div>
                        <div className="flex w-full py-4 space-x-2 border-b mt- border-b-gray-500 ">
                          <div className="flex items-start text-sm font-bold ">
                            <span className="mr-4 text-gray-700">
                              المعدّل / المجموع :
                            </span>
                            <span className="mr-2 "> {diploma?.average} </span>
                          </div>
                        </div>
                        <div className="flex w-full py-4 space-x-2 border-b border-b-gray-300">
                          <div className="flex items-start text-sm font-bold ">
                            <span className="mr-4 text-gray-700">
                              الجامعة :
                            </span>
                            <span className="mr-2 ">
                              {diploma?.university.name}
                            </span>
                          </div>
                        </div>
                        <div className="flex w-full py-4 space-x-2 border-b mt- border-b-gray-500 ">
                          <div className="flex items-start text-sm font-bold ">
                            <span className="mr-4 text-gray-700">
                              الملاحظة :
                            </span>
                            <span className="mr-2 "> {diploma?.honors} </span>
                          </div>
                        </div>
                        <div className="flex flex-col items-center justify-center w-full mt-20">
                          <span className="text-4xl font-bold text-green-500">
                            تم التثبت من صحة الشهادة
                          </span>
                          <img
                            src={certifiedLogo}
                            alt="certified"
                            className="w-20 h-20 mt-8 "
                          />
                        </div>
                      </div>
                    )}
                  </div>
                  <div className="flex flex-col items-center justify-center w-full overflow-hidden">
                    <span className="text-lg text-blue-900">
                      صورة الشهادة على البلوكتشين
                    </span>
                    {spinnerActive ? (
                      <Spinner />
                    ) : (
                      <div className="verification">
                        <Document
                          file={"https://esi.alecso.org/ipfs/" + hashIpfs}
                          className="flex items-center justify-center"
                        >
                          <Page pageNumber={1} />
                        </Document>
                        <div className="items-center justify-center w-full mt-8">
                          <a
                            target="_blank"
                            href={`https://ftmscan.com/tx/${diploma.hashTx}`}
                            className="text-blue-900 hover:text-blue-900"
                          >
                            <span>رابط تخزين الشهادة على البلوكتشين</span>
                          </a>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div className="mt-16">
              <span className="text-4xl font-bold text-blue-900">
                هذه الشهادة غير موجودة
              </span>
            </div>
          )}
        </div>
      ) : (
        <div className="flex flex-col items-center justify-center w-full h-full">
          <span className="text-4xl font-bold text-blue-900">
            عملية التحقق جارية
          </span>
          <Spinner />
        </div>
      )}
    </div>
  );
}

export default VerificationPage;
