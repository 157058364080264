import React, { useState } from "react";
import { Document, Page } from "react-pdf/dist/esm/entry.webpack";
import PropTypes from "prop-types";
import QRCode from "qrcode.react";
import DiplomaModalWithCloseButton from "../../components/modal/Diploma/DiplomaModalWithCloseButton";

const DiplomaInfo = ({ diploma }) => {
  const [open, setOpen] = useState(false);
  const handleOpen = () => {
    setOpen(!open);
  };
  const [
    linkToVerificationPageQrCodeClicked,
    setLinkToVerificationPageQrCodeClicked,
  ] = useState(false);

  const onLinkVerificationClick = () => {
    setLinkToVerificationPageQrCodeClicked(
      !linkToVerificationPageQrCodeClicked
    );
  };
  const downloadQRCode = (fileName) => {
    console.log("clicked");

    // Generate download with use canvas and stream
    const canvas = document.getElementById("qr-gen");
    if (canvas) {
      const pngUrl = canvas
        .toDataURL("image/png")
        .replace("image/png", "image/octet-stream");
      let downloadLink = document.createElement("a");
      downloadLink.href = pngUrl;
      downloadLink.download = fileName + `qr.png`;
      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);
    }
  };
  return (
    <>
      {diploma?.status === "rejected" ? (
        <></>
      ) : (
        <>
          <div className="flex flex-col items-start justify-start   w-[full] h-[auto] rounded-xl border-2 border-blue-50 mx-4 mb-4 pt-8">
            <div className="w-auto rounded-t-xl">
              <Document
                file={`https://esi.alecso.org/ipfs/${diploma.hashIpfs}`}
                className=" rounded-t-xl"
              >
                <Page pageNumber={1} />
              </Document>
            </div>

            <span className="p-4 text-xl font-bold text-blue-900"></span>
            <div className="w-full mb-4 bg-blue-50">
              <div className="flex w-full py-4 space-x-2 border-b mt- border-b-gray-500 ">
                <div className="flex items-start text-sm font-bold ">
                  <span className="mr-4 text-gray-700"> الاختصاص: </span>
                  <span className="mr-2 "> {diploma.speciality}</span>
                </div>
              </div>
              <div className="flex w-full py-4 space-x-2 border-b border-b-gray-500">
                <div className="flex items-start text-sm font-bold ">
                  <span className="mr-4 text-gray-700"> السنة الجامعية :</span>
                  <span className="mr-2 ">{diploma.year} </span>
                </div>
              </div>
              <div className="flex w-full py-4 space-x-2 border-b border-b-gray-500">
                <div className="flex items-start text-sm font-bold ">
                  <span className="mr-4 text-gray-700"> الملاحظة :</span>
                  <span className="mr-2 "> {diploma.honors}</span>
                </div>
              </div>
              <div className="flex w-full py-4 space-x-2 border-b border-b-gray-500">
                <div className="flex items-start text-sm font-bold ">
                  <span className="mr-4 text-gray-700">
                    المعدّل / المجموع :
                  </span>
                  <span className="mr-2 ">{diploma.average} </span>
                </div>
              </div>
              <div className="flex w-full py-4 space-x-2 border-b border-b-gray-300">
                <div className="flex items-start text-sm font-bold ">
                  <span className="mr-4 text-gray-700"> الجامعة :</span>
                  <span className="mr-2 ">{diploma?.university.name}</span>
                </div>
              </div>
            </div>
            <div className="flex w-full border-b-gray-500"></div>
            <div className="flex w-full border-b-gray-300 mb-5">
              <button
                onClick={() => {
                  handleOpen();
                }}
                className="h-10 p-2 mx-2 text-sm  text-white bg-[#5ddab4] rounded-md w-fit"
              >
                اطّلاع
              </button>
              <button
                className="h-10 p-2 mx-2 text-sm text-white bg-red-300 rounded-md w-fit"
                onClick={onLinkVerificationClick}
              >
                رابط التحقق
              </button>
            </div>
            {linkToVerificationPageQrCodeClicked && (
              <>
                <div className="w-full h-fit">
                  <QRCode
                    id="qr-gen"
                    size={256}
                    style={{ height: "auto", maxWidth: "100%", width: "100%" }}
                    value={`https://esi.alecso.org/verification/${diploma?._id}`}
                    viewBox={`0 0 256 256`}
                  />
                </div>
                <a
                  href={`https://esi.alecso.org/verification/${diploma?._id}`}
                  target="_blank"
                  rel="noreferrer"
                >
                  رابط التحقق
                </a>
                <button
                  className="h-10 p-2 mt-4 mb-3 mx-2 text-sm text-white bg-red-300 rounded-md w-fit"
                  onClick={() => {
                    downloadQRCode(diploma?.hashIpfs);
                  }}
                >
                  تحميل
                </button>
              </>
            )}
          </div>
          {open ? (
            <DiplomaModalWithCloseButton item={diploma} setOpen={setOpen} />
          ) : null}
        </>
      )}
    </>
  );
};
DiplomaInfo.propTypes = {
  diploma: PropTypes.object,
};

export default DiplomaInfo;
